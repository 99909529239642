/**
 * Component: SalesQuote
 */

import { view, store } from '@risingstack/react-easy-state'
import {
  Box,
  Table,
  Thead,
  Tbody,
  Tr,
  Th,
  Td,
  Divider,
  UnorderedList,
  ListItem,
  Alert,
  AlertIcon,
  AlertTitle,
  AlertDescription,
  Tooltip,
} from '@chakra-ui/react'
import { QuestionOutlineIcon } from '@chakra-ui/icons'
import styles from './SalesQuote.module.css'

/**
 * Datadog Calculate
 * 
 * This was taken from Datadog's pricing information.
 * The rates are monthly rates, not annual rates.
 * They are all per million metrics, logs, and 30 day retention for spans.
 */

const calculateDatadog = (transactions) => {
  const items = [
    { name: 'Metrics', total: Math.round(transactions * 7.00) },
    { name: 'Custom Metrics', total: Math.round(transactions * 0.75) }, // $5 per 100 Custom Metrics, w/ est. 5 Custom Metrics per 1M
    { name: 'Log Ingestion', total: Math.round(transactions * 0.50) }, // 5kb per transaction = 5GB per 1M transactions @ $0.10/GB
    { name: 'Log Retention', total: Math.round(transactions * 3.75) },
    { name: 'Indexed Spans', total: Math.round(transactions * 3.79) },
  ]

  let total = 0
  items.forEach((i) => { total = total + i.total })
  total = Math.round(total)

  return { total, items }
}

/**
 * Cloudwatch Calculate
 * 
 * Calculates monthly cost
 */

const calculateCloudwatch = (transactions) => {
  const items = [
    { name: 'Metrics', total: 240 }, // For 100 functions (each has 8 metrics)
    { name: 'Custom Metrics', total: 300 }, // Monthly fee to support all of the unique tagging capabilities people will likely use (an additional 10 metrics for 100 functions)
    { name: 'Traces + Logs, Ingestion & Retention', total: Math.round(transactions * 5.93) },  // Estimate is based on 9KB per Transaction which contains the primary request but also the spans which we ingest and store.
    { name: 'Insights Queries', total: Math.round(transactions * 0.25) || 1 }, // Estimate is based on 3 queries per 1M transactions scanned
  ]

  let total = 0
  items.forEach((i) => { total = total + i.total })
  total = Math.round(total)

  return { total, items }
}

/**
 * Component
 */

export default view((props) => {

  const { quote } = props
  console.log(quote)

  const localState = store({

    total: 0,

    competitiveAnalysis: {
      Datadog: {
        total: 0,
        items: [],
        notes: [
          `This is an estimate based on recreating our features on this product.`,
          `This product's pricing is complex.`,
        ]
      },
      AWS_Cloudwatch_XRay: {
        total: 0,
        items: [],
        notes: [
          `This is an estimate based on recreating our features on this product.`,
          `This product's pricing is complex.`,
          `This product is much slower and prices reflect their colder storage. 15s to several minutes for metrics, logs and queries.`,
          `The pricing for simply having Metrics and Custom Metrics for ~100 functions is a fixed price.`
        ]
      }
    },

    processQuote: () => {

      localState.totalTeamSeatsMonthly = Math.round(localState.quote.teamSeats * localState.quote.teamSeatPrice)
      localState.totalTransactionsMonthly = Math.round(((localState.quote.transactions || 0) * (localState.quote.transactionsPrice || 0)))
      localState.totalSupportMonthly = parseInt(localState.quote.supportPrice || 0)
      const total = Math.round(localState.totalTransactionsMonthly + localState.totalSupportMonthly + localState.totalTeamSeatsMonthly)
      localState.total = total * localState.quote.termMonths

      const quoteDatadog = calculateDatadog((localState.quote.transactions || 0))
      localState.competitiveAnalysis.Datadog.total = quoteDatadog.total
      localState.competitiveAnalysis.Datadog.items = quoteDatadog.items

      const quoteCloudwatch = calculateCloudwatch((localState.quote.transactions || 0))
      localState.competitiveAnalysis.AWS_Cloudwatch_XRay.total = quoteCloudwatch.total
      localState.competitiveAnalysis.AWS_Cloudwatch_XRay.items = quoteCloudwatch.items

    }
  })

  // Validate to ensure number values are numbers
  const teamSeatPrice = isNaN(quote.teamSeatPrice) ? 0 : quote.teamSeatPrice
  const teamSeats = isNaN(quote.teamSeats) ? 0 : quote.teamSeats
  const transactions = isNaN(quote.transactions) ? 0 : quote.transactions
  const transactionsPrice = isNaN(quote.transactionsPrice) ? 0 : quote.transactionsPrice
  const supportPrice = isNaN(quote.supportPrice) ? 0 : quote.supportPrice

  // Set quote in local state
  localState.quote = { 
    ...quote,
    teamSeatPrice,
    teamSeats,
    transactions,
    transactionsPrice,
    supportPrice,
  }

  // Process Quote
  localState.processQuote()

  return (
    <div className={styles.container}>

      <div className={styles.logo}>
        <img src={'https://assets.serverless-extras.com/general/logo-serverless-framework-quote-light.png'} alt='Serverless Framework'/>
      </div>

      <Divider mt={10} mb={10} />

      {/* Quote */}

      <div className={styles.headerColumns}>
        <div className={styles.headerColumnOne}>
          <div className={styles.quoteCompanyName}>{localState.quote.companyName}</div>
          <div className={styles.quoteTerm}>Term: {localState.quote.termMonths} Months</div>
        </div>
        <div className={styles.headerColumnTwo}>
          <div className={styles.quoteTotal}>Total: &nbsp;${(localState.total).toLocaleString('en')}</div>
        </div>
      </div>

      {/* Quote Summary */}

      <div className={styles.sectionSummary}>
        This quote is for a Serverless Subscription annual contract which includes all features of the Serverless Framework Dashboard.  This total price is based on team seats{localState.quote.supportPrice ? ', ' : ' and ' } a reserved amount of monthly monitoring transactions for a discounted price{localState.quote.supportPrice ? ', as well as dedicated support for your team' : ', but dedicated support is not included' }.  If you're ready to move forward or have questions, reach out to your account representative, or send an email to sales@serverless.com 
      </div>

      <div className={styles.sectionHeader}>
          What's Included
      </div>

      <div className={styles.sectionFeatures}>
        <div className={styles.feature}>
          <img src={'https://assets.serverless-extras.com/general/quote-icon-lightning.png'} alt='Organized Apps'/>
          <div className={styles.featureTitle}>Organized Apps</div>
          <div className={styles.featureDetails}>See your serverless apps and stages all in one place—across any number of AWS accounts.</div>
        </div>

        <div className={styles.feature}>
          <img src={'https://assets.serverless-extras.com/general/quote-icon-aws.png'} alt='Protect AWS'/>
          <div className={styles.featureTitle}>Protect AWS</div>
          <div className={styles.featureDetails}>Securely assign AWS accounts to different apps and stages & protect how your developers access them.</div>
        </div>
        <div className={styles.feature}>
          <img src={'https://assets.serverless-extras.com/general/quote-icon-key.png'} alt='Secrets Management'/>
          <div className={styles.featureTitle}>Secrets</div>
          <div className={styles.featureDetails}>Store & manage secrets for your applications securely in one place. Use them across apps & AWS accounts.</div>
        </div>
        <div className={styles.feature}>
          <img src={'https://assets.serverless-extras.com/general/quote-icon-analytic.png'} alt='Serverless Metrics'/>
          <div className={styles.featureTitle}>Monitoring</div>
          <div className={styles.featureDetails}>View transactions, errors, cold-starts & more across all of your serverless applications.</div>
        </div>
        <div className={styles.feature}>
          <img src={'https://assets.serverless-extras.com/general/quote-icon-warning.png'} alt='Serverless Metrics'/>
          <div className={styles.featureTitle}>Alerts</div>
          <div className={styles.featureDetails}>Get alerts on new errors, unusual error rates, timeout warnings, unusual transaction volume & much more.</div>
        </div>
        <div className={styles.feature}>
          <img src={'https://assets.serverless-extras.com/general/quote-icon-search.png'} alt='Serverless Explorer'/>
          <div className={styles.featureTitle}>Explorer</div>
          <div className={styles.featureDetails}>Real-time querying of every AWS Lambda invocation & API request to troubleshoot errors & end-user issues.</div>
        </div>
        <div className={styles.feature}>
          <img src={'https://assets.serverless-extras.com/general/quote-icon-group.png'} alt='Team Management'/>
          <div className={styles.featureTitle}>Teams</div>
          <div className={styles.featureDetails}>Onboard & offboard team members easily, and enable them to quickly start developing.</div>
        </div>

        {localState.quote.supportPrice ? 
          (<div className={styles.feature}>
            <img src={'https://assets.serverless-extras.com/general/quote-icon-emoticon.png'} alt='Dedicated Slack Support'/>
            <div className={styles.featureTitle}>Expert Support</div>
            <div className={styles.featureDetails}>Get an architectural review & dedicated Slack support channel where you have direct access to our team.</div>
          </div>) : ''}
      </div>

      <div className={styles.sectionHeader}>
          Price Breakdown
      </div>

      <Table size="sm" className={styles.quoteTable}>
        <Thead>
          <Tr>
            <Th className={styles.quoteHeader}>Users <Tooltip label="Each user in your Serverless Framework Dashboard Organization requires a team seat.  Each team seat has a fee." hasArrow arrowSize={15}><QuestionOutlineIcon w={4} h={4} mb={1} ml={1} color="gray.500"/></Tooltip></Th>
            <Th></Th>
          </Tr>
        </Thead>
        <Tbody>
          <Tr>
            <Td>Seats</Td>
            <Td isNumeric>{localState.quote.teamSeats}</Td>
          </Tr>
          <Tr>
            <Td>Price</Td>
            <Td isNumeric>${localState.quote.teamSeatPrice} <span className={styles.units}>/ User / Month</span></Td>
          </Tr>
          <Tr>
            <Td>Total</Td>
            <Td isNumeric>${((localState.quote.teamSeatPrice * localState.quote.teamSeats) * localState.quote.termMonths).toLocaleString('en')} <span className={styles.units}>/ {localState.quote.termMonths} Months</span></Td>
          </Tr>
        </Tbody>
        <Thead>
          <Tr>
            <Th className={styles.quoteHeader}>Transactions <Tooltip label="A Monitoring Transaction is a data record containing performance information about a single serverless compute execution (e.g. AWS Lambda invocation). Monitoring Transactions power our charts, alerts, and Explorer.  Transactions are charged at $70/Million/Month.  Reserving Transactions in an annual contract unlocks large discounts.  Please note the Overage Price is the rate you will be charged if you exceed your Reserved Transactions.  There are no rollover Transactions." hasArrow arrowSize={15}><QuestionOutlineIcon w={4} h={4} mb={1} ml={1} color="gray.500"/></Tooltip></Th>
            <Th></Th>
          </Tr>
        </Thead>
        <Tbody>
          <Tr>
            <Td>Reserved Amount</Td>
            <Td isNumeric>{ (localState.quote.transactions * 1000000).toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",") } <span className={styles.units}>/ Month</span></Td>
          </Tr>
          <Tr>
            <Td>Reserved Price</Td>
            <Td isNumeric>${ localState.quote.transactionsPrice } <span className={styles.units}>/ Million / Month</span></Td>
          </Tr>
          <Tr>
            <Td>Reserved Total</Td>
            <Td isNumeric>${ (localState.totalTransactionsMonthly * localState.quote.termMonths).toLocaleString('en') } <span className={styles.units}>/ { localState.quote.termMonths } Months</span></Td>
          </Tr>
          <Tr>
            <Td>Overage Price</Td>
            <Td isNumeric>${ localState.quote.transactionsPriceOverage } <span className={styles.units}>/ Million / Month</span></Td>
          </Tr>
        </Tbody>
        <Thead>
          <Tr>
            <Th className={styles.quoteHeader}>Support <Tooltip label="Our dedicated support includes an architectural review, Slack channel, and more.  This quote only includes the ability for 3 of your teammates to join the Slack channel and file support tickets." hasArrow arrowSize={15}><QuestionOutlineIcon w={4} h={4} mb={1} ml={1} color="gray.500"/></Tooltip></Th>
            <Th></Th>
          </Tr>
        </Thead>
        <Tbody>
          <Tr>
            <Td>Total</Td>
            <Td isNumeric>{ localState.quote.supportPrice ? `$${ (localState.quote.supportPrice * localState.quote.termMonths).toLocaleString('en') }` : 'N/A' } {localState.quote.supportPrice ? (<span className={styles.units}>/ {localState.quote.termMonths} Months </span>) : ''}</Td>
          </Tr>
        </Tbody>
        <Thead>
          <Tr>
            <Th className={styles.quoteHeader}>Total</Th>
            <Th></Th>
          </Tr>
        </Thead>
        <Tbody>
          <Tr>
            <Td>Total</Td>
            <Td isNumeric>${ (localState.total).toLocaleString('en') } <span className={styles.units}>/ {localState.quote.termMonths} Months</span></Td>
          </Tr>
        </Tbody>
      </Table>


      {/* Competitive Analysis */}

      {localState.quote.showCompetitiveAnalysis && (
        <div className={styles.sectionCompetitiveAnalysis}>

          <div className={styles.sectionHeader}>Monitoring – Competitive Analysis</div>

          <div className={styles.sectionSummary}>
            Monitoring products often have complicated (or seemingly low) pricing.  So, we did the cost comparison work for you.  Here's what it costs to recreate all of Serverless Framework's monitoring features on other vendors.
          </div>

          <div spacing="40px" mt='10' className={styles.competitorsContainer}>
            {Object.keys(localState.competitiveAnalysis).map((vendor, i) => {
              return (
                <div key={i} className={styles.competitor}>

                  <div className={styles.competitorName}>{ vendor.replaceAll('_', ' ') }</div>

                  <Table size="sm" className={styles.quoteTable}>
                    <Tbody>
                      <Tr className={styles.competitorTotalRow}>
                        <Td>Total Annual</Td>
                        <Td isNumeric>${ (localState.competitiveAnalysis[vendor].total * localState.quote.termMonths).toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",") } </Td>
                      </Tr>
                      {localState.competitiveAnalysis[vendor].items.map((item, j) => {
                        return (
                          <Tr className={styles.competitorStatRow} key={j}>
                            <Td>{ item.name }:</Td>
                            <Td isNumeric>${ (item.total * localState.quote.termMonths).toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",") }</Td>
                          </Tr>
                        )
                      })}
                      
                    </Tbody>
                  </Table>
                  <div className={`${styles.competitorNotes}`}>
                    {localState.competitiveAnalysis[vendor].notes.map((note, k) => { 
                      return (<div className={styles.competitorNote} key={k}> • { note }</div>)
                    })}
                  </div>
                </div>
            )})}
          </div>

        </div>
      )}

      <div className={styles.sectionSupportDetails}>
        <div className={styles.sectionHeader}>
            Support Overview
        </div>

        <div className={styles.sectionSummary}>
          Here's a breakdown on why our support offering is unlike anything else you'll find available today.
        </div>

        {localState.quote.supportPrice === 0 && (
          <Alert status="error" mt={0} mb={10}>
            <AlertIcon mr={5} />
            <Box flex="1">
              <AlertTitle>Support is not included in this quote</AlertTitle>
              <AlertDescription display="block">
              Since you have opted out of support, below is not included.
              </AlertDescription>
            </Box>
          </Alert>
          
        )}

        <div className={styles.sectionSupportColumns}>
          <div className={styles.sectionSupportColumn}>
            <div className={styles.sectionSupportColumnHeader}>
              Architectural Review
            </div>
            <div className={styles.sectionSupportColumnDetails}>
              <UnorderedList>
                <ListItem>Every support agreement starts with a 2 hour architectural review between your team and our dedicated support representative and product leaders.</ListItem>
                <ListItem>The goal of the review is to identify and optimize patterns across your serverless use-cases and workflows, and make those easier repeat to increase productivity and guidance for your team.</ListItem>
                <ListItem>We'll also answer general architectural and workflow questions, and discuss best practices.</ListItem>
              </UnorderedList>
            </div>
          </div>
          <div className={styles.sectionSupportColumn}>
            <div className={styles.sectionSupportColumnHeader}>
              Dedicated Slack Channel
            </div>
            <div className={styles.sectionSupportColumnDetails}>
              <UnorderedList>
                <ListItem>Every support agreement gets a dedicated Slack channel which we staff with a dedicated support representative and some of our product leaders.</ListItem>
                <ListItem>This is a great forum for getting into detailed conversations on best practices or support inquiries.  Our response rate is typically faster here than anywhere else.</ListItem>
              </UnorderedList>
            </div>
          </div>
          <div className={styles.sectionSupportColumn}>
            <div className={styles.sectionSupportColumnHeader}>
              Street-Wise Experts & SLAs
            </div>
            <div className={styles.sectionSupportColumnDetails}>
              <UnorderedList>
                <ListItem>We only hire real-world serverless architecture practitioners, largely from our open-source communities, and offer honest advice unbiased from any cloud vendor.  We bring the street-smarts, so you can make the right decisions.</ListItem>
                <ListItem>We offer a 24 hour response time SLA.  Support hours are limited to business hours, 9AM to 5PM on weekdays, and excluding US holidays.  Though, our internal anlytics show our typical response time is within 45 minutes.</ListItem>
                <ListItem>This quote only includes the ability for 3 of your teammates to join the Slack channel and file support tickets.</ListItem>
              </UnorderedList>
            </div>
          </div>
        </div>
      </div>

    </div>
  )
})