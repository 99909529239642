/**
 * Component: App
 */

import { useEffect } from 'react'
import { view } from '@risingstack/react-easy-state'
import { BrowserRouter as Router, Route, Switch, Redirect } from 'react-router-dom'
import { ChakraProvider } from '@chakra-ui/react'
import theme from '../theme'
import state from '../state'
import PageLoading from './PageLoading'
import PageAuth from './PageAuth'
import PageHome from './PageHome'
import PageQuotes from './PageQuotes'
import PagePublicQuote from './PagePublicQuote'

export default view(() => {

  useEffect(() => { state.init() }, [])

  const PrivateRoute = view(({ component, ...options }) => {
    if (state.isSignedIn) {

      // If signed in, the root path is always "/admin"
      if (window.location.pathname === '/') return ( <Redirect to='/admin' /> )
      else return ( <Route {...options} component={component} /> )

    } else {
      if (window.location.pathname !== '/') return ( <Redirect to='/' /> )
      else return ( <Route {...options} component={component} /> )
    }
  })

  /**
   * Loading View
   */

  if (!state.isInitialized) { return <PageLoading/> }

  /**
   * Routes
   */

  return (
    <ChakraProvider theme={theme}>
      <Router>
        <Switch>
          <PrivateRoute exact path='/' component={PageAuth} />
          <PrivateRoute exact path='/admin' component={PageHome} />
          <PrivateRoute path='/admin/quotes' component={PageQuotes} />

          {/* Public Routes */}

          <Route exact path='/quotes/:quoteID/' component={PagePublicQuote} />
        </Switch>
      </Router>
    </ChakraProvider>
  )
})