/**
 * Component: Nav
 */

import { view } from '@risingstack/react-easy-state'
import { NavLink } from 'react-router-dom'
import state from '../state'
import styles from './Nav.module.css'

export default view(() => {

    return (
      <div className={styles.container}>

        {state.isInitialized &&
          <div className={styles.items}>
            <NavLink className={styles.item} exact activeClassName={styles.isActive} to='/admin'>Home</NavLink>
            <NavLink className={styles.item} exact activeClassName={styles.isActive} to='/admin/quotes'>Quotes</NavLink>
            <div className={styles.item} onMouseUp={state.signOut}>Sign Out</div>
          </div>
        }

      </div>
    )
})