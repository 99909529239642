/**
 * Component: PageAuth
 */

import { view } from '@risingstack/react-easy-state'
import state from '../state'
import styles from './PageAuth.module.css'

export default view(() => {

    return (
      <div className={styles.page}>

        {state.isInitialized && !state.isSignedIn &&
          <div className={styles.signIn} onMouseUp={state.signIn}>Sign In</div>
        }
  
      </div>
    )
})