/**
 * Component: PageLoading
 */

import { view } from '@risingstack/react-easy-state'
import styles from './PageLoading.module.css'

export default view((props) => {
    return (
        <div className={`${styles.page} ${styles.light}`}>
          <div className={styles.loading}>Loading...</div>
        </div>
    )
})