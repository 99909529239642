import { extendTheme } from "@chakra-ui/react"

export default extendTheme({
    fonts: {
        body: 'Helvetica Neue, sans-serif',
        heading: 'Helvetica Neue, sans-serif',
        mono: 'Menlo, monospace',
    },
    colors: {
        gray: {
            "50": "#F2F2F2",
            "100": "#DBDBDB",
            "200": "#C4C4C4",
            "300": "#ADADAD",
            "400": "#969696",
            "500": "#808080",
            "600": "#666666",
            "700": "#4D4D4D",
            "800": "#333333",
            "900": "#1A1A1A"
        },
        red: {
            "50": "#FFE7E6",
            "100": "#FEBBB8",
            "200": "#FE908B",
            "300": "#FD645E",
            "400": "#FD5750",
            "500": "#FD5750",
            "600": "#CA0A02",
            "700": "#970802",
            "800": "#650501",
            "900": "#320301"
        }
    }
    
})