/**
 * Component: PageQuotePublic
 */

import { useEffect } from 'react'
import { view, store } from '@risingstack/react-easy-state'
import { motion } from 'framer-motion'
import PageLoading from './PageLoading'
import SalesQuote from './SalesQuote'
import state from '../state'
import styles from './PagePublicQuote.module.css'

export default view(() => {


    const localState = store({

      initialized: false,
      quote: null,

      getQuote: async (quoteURLPath) => {
        let quote = await state.getQuoteByURLPath(quoteURLPath) 
        localState.quote = quote.quote
        localState.initialized = true
      }
    })

    useEffect(() => {
      document.title = 'Serverless Inc - Quote'
      const quoteURLPath = window.location.pathname ? window.location.pathname.substring(window.location.pathname.lastIndexOf('/') + 1) : null
      localState.getQuote(quoteURLPath)
    }, [localState])

    if (!localState.initialized) { return <PageLoading light={true} /> }

    if (localState.initialized && !localState.quote) { 
      return (
        <div className={styles.page}>
          <div className={styles.quoteMissing}>
            Sorry, this quote could not be found – Contact us at sales@serverless.com
          </div>
        </div>
      )
    }

    return (
      <div className={styles.page}>
        <div className={styles.quoteContainer}>
          {localState.initialized && (
          <motion.div className={styles.motionContainer}
              animate={{ 
                y: [200,0],
                opacity: [0, 1]
              }}
              transition={{ delay: 0, duration: 1, ease: 'easeOut' }}>
                <SalesQuote quote={localState.quote} />
            </motion.div>)}
        </div>
      </div>
    )
})