/**
 * Component: Home
 */

import { view } from '@risingstack/react-easy-state'
import styles from './PageHome.module.css'
import Nav from './Nav'
import { NavLink } from 'react-router-dom'

export default view(() => {

    return (
      <div className={styles.page}>

        <Nav />

        <div className={styles.notice}>
          This page is a work-in-progress.  Go to the <NavLink className={styles.link} exact to='/admin/quotes'>Quotes</NavLink> page to make a sales quote.
        </div>
  
      </div>
    )
})