/**
 * Component: PageQuotes
 */

import { useEffect } from 'react'
import { view, store } from '@risingstack/react-easy-state'
import { motion } from 'framer-motion'
import state from '../state'
import styles from './PageQuotes.module.css'
import Nav from './Nav'
import SalesQuote from './SalesQuote'
import { Button, useToast, Checkbox } from '@chakra-ui/react'


export default view(() => {

  const toast = useToast()

  /**
   * Local State
   */

  const localState = store({

    initialized: false,
    isSaving: false,
    isDeleting: false,

    newQuote: false,
    activeIndex: 0,
    quote: {},

    handleSelect: (quote, i) => {
      localState.quote = quote
      localState.activeIndex = i 
    },

    handleChange: (e) => {
      localState.quote[e.target.name] = e.target.type === 'number' ? parseInt(e.target.value) : e.target.value
    },

    copyUrlPath: async () => {
      const url = `${window.location.hostname}/quotes/${localState.quote.urlPath}`
      await navigator.clipboard.writeText(url)

      toast({
        title: "URL Copied",
        // description: "We've created your account for you.",
        status: "success",
        position: 'bottom-left',
        duration: 3000,
        isClosable: true,
      })
    },

    init: async () => {
      await state.getQuotes()

      if (!state.quotes.length) { localState.newQuote = true }
      else { localState.quote = state.quotes[0] }

      localState.initialized = true
    },

    initNewQuote: async () => {
      const quote = await state.initQuote()
      localState.quote = quote.quote
      localState.newQuote = true
      localState.activeIndex = null
    },

    saveQuote: async () => {
      localState.isSaving = true
      console.log(localState.quote)
      try {
        const q = await state.saveQuote(localState.quote)
        localState.quote = q.quote
      } catch(error) {
        toast({
          title: error.message,
          status: "error",
          position: 'bottom-left',
          duration: 4000,
          isClosable: true,
        })
        localState.isSaving = false
        return
      }

      await state.getQuotes()
      localState.newQuote = false
      localState.activeIndex = 0
      localState.isSaving = false

      toast({
        title: "Quote saved",
        status: "success",
        position: 'bottom-left',
        duration: 3000,
      })
    },

    destroyQuote: async () => {
      localState.isDeleting = true
      try {
        await state.destroyQuote(localState.quote.id)
      } catch(error) {
        toast({
          title: error.message,
          status: "error",
          position: 'bottom-left',
          duration: 4000,
        })
        localState.isDeleting = false
        return
      }

      await state.getQuotes()

      if (!state.quotes.length) { localState.newQuote = true }
      else { 
        localState.quote = state.quotes[0]
        localState.activeIndex = 0 
      }
      localState.isDeleting = false
    },

    cancelQuote: async () => {
      localState.newQuote = false
      localState.quote = state.quotes[0] || {}
      localState.activeIndex = state.quotes[0] ? 0 : null
    },
  })

  useEffect(() => {  localState.init() }, [localState])

  /**
   * Component
   */

  return (
    <div className={styles.page}>

      <Nav />

      <div className={styles.columns}>
        <div className={styles.columnOne}>

          {!state.quotes.length && localState.initialized
            ? <></>
            : (<>
               {state.quotes.map((quote, i) => { 
                  return ( 
                    <div key={i} className={`${styles.quoteItem} ${localState.quote.id === quote.id ? styles.activeQuoteItem : ''}`} onMouseDown={() => { localState.handleSelect(quote, i) }}>
                      <div className={styles.quoteItemDate}>{new Date(quote.timeCreated).toLocaleDateString('en-US')}</div>
                      <div className={styles.quoteItemCompany}>{quote.companyName}</div>
                    </div> 
                  )
                })}
            </>)
          }

        </div>

        <div className={styles.columnTwo}>
          {localState.initialized && (
            <div className={styles.quoteInputsContainer}>

              <div className={styles.quoteInputsTitle}>
                {localState.newQuote ? 'Create A New Quote' : 'Quote' }
              </div>

              {!localState.newQuote && (<div className={styles.quoteInputsMode}>
                  <Button colorScheme="gray" variant='link' size="sm" mb={2} onMouseDown={localState.initNewQuote}>Create A New Quote</Button> 
                  <Button colorScheme="gray" variant='link' size="sm" onMouseDown={localState.copyUrlPath}>Copy Quote Link</Button> 
                </div>
              )}

              <div className={styles.quoteInputLabel}>Company Name</div>
              <input
                name='companyName'
                type='text'
                value={localState.quote.companyName}
                onChange={localState.handleChange}
                placeholder='Acme Inc'
              />

              <div className={styles.quoteInputLabel}>Term (In Months)</div>
              <input
                name='termMonths'
                type='number'
                value={localState.quote.termMonths}
                onChange={localState.handleChange}
                placeholder='12'
              />

              <div className={styles.quoteInputsHeader}>Team Seats</div>

              <div className={styles.quoteInputLabel}>Team Seats Price / Month</div>
              <input
                name='teamSeatPrice'
                type='number'
                value={Number(localState.quote.teamSeatPrice)}
                onChange={localState.handleChange}
                placeholder='20'
              />

              <div className={styles.quoteInputLabel}>Team Seats</div>
              <input
                name='teamSeats'
                type='number'
                value={localState.quote.teamSeats}
                onChange={localState.handleChange}
                placeholder='20'
              />

              <div className={styles.quoteInputsHeader}>Transactions</div>

              <div className={styles.quoteInputLabel}>Reserved Price / Million / Month</div>
              <input
                name='transactionsPrice'
                type='number'
                value={localState.quote.transactionsPrice}
                onChange={localState.handleChange}
                placeholder='70'
              />

              <div className={styles.quoteInputLabel}>Reserved Volume / Million / Month</div>
              <input
                name='transactions'
                type='number'
                value={localState.quote.transactions}
                onChange={localState.handleChange}
                placeholder='10'
              />

              <div className={styles.quoteInputLabel}>Overage Price / Million / Month</div>
              <input
                name='transactionsPriceOverage'
                type='number'
                value={localState.quote.transactionsPriceOverage}
                onChange={localState.handleChange}
                placeholder='70'
              />

              <div className={styles.quoteInputsHeader}>Support</div>

              <div className={styles.quoteInputLabel}>Support Price / Month</div>
              <input
                name='supportPrice'
                type='number'
                value={localState.quote.supportPrice}
                onChange={localState.handleChange}
                placeholder='2000'
              />

              <div className={styles.quoteInputsHeader}>Options</div>

              <Checkbox className={styles.quoteCheckbox} name='showCompetitiveAnalysis' size="lg" colorScheme="gray" isChecked={localState.quote.showCompetitiveAnalysis} onChange={(e) => { localState.quote.showCompetitiveAnalysis = localState.quote.showCompetitiveAnalysis ? false : true }}>
                  Show Monitoring Competitive Analysis
              </Checkbox>

              <div className={styles.quoteInputsActions}>
                {!localState.isDeleting && !localState.newQuote && <Button colorScheme="red" isLoading={localState.isSaving} onMouseDown={localState.saveQuote}>Update Quote</Button> }
                {!localState.isSaving && !localState.newQuote && <Button className={styles.btnSecondary} colorScheme="gray" variant='link' isLoading={localState.isDeleting} onMouseDown={localState.destroyQuote}>Delete Quote</Button> }

                {localState.newQuote && <Button colorScheme="red" isLoading={localState.isSaving} onMouseDown={localState.saveQuote}>Create New Quote</Button> }
                {!localState.isSaving && localState.newQuote && <Button className={styles.btnSecondary} colorScheme="gray" variant='link' isLoading={localState.isDeleting} onMouseDown={localState.cancelQuote}>Cancel New Quote</Button> }
              </div>

            </div>
          )}
        </div>

        <div className={styles.columnThree}>
        {localState.initialized && (
          <motion.div className={styles.motionContainer}
              animate={{  x: [200,0], opacity: [0, 1] }}
              transition={{ delay: 0.6, duration: 0.75 }}>
                <SalesQuote quote={localState.quote} />
            </motion.div>)}
        </div>
        
      </div>
    </div>
  )
})