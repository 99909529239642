import { store } from '@risingstack/react-easy-state'

/**
 * Config
 */
const config = {}

if (process.env.NODE_ENV === 'development') {
    config.url = 'https://sharp-mvp-bi0rx.cloud.serverless.com'
} else {
    config.url = 'https://api.serverless-inc.com'
}

const state = store({

    /**
     * State
     */

    isInitialized: false,
    isSignedIn: false,
    idToken: null,

    loadingQuotes: true,
    quotes: [],

    init: async () => {

        await new Promise((res, rej) => {
            setTimeout(() => {
                window.gapi.load("client:auth2", { callback: res, onerror: rej })
            }, 500)
        })

        await window.gapi.client.init({
            clientId: '123230491375-4iqub5ulhbop4olp0ulimriovlhilr8i.apps.googleusercontent.com',
            scope: 'email'
        })

        await new Promise((res) => {
            const auth = window.gapi.auth2.getAuthInstance()
            // If the user is already signed in, get token
            state.isSignedIn = auth.isSignedIn.get() || false
            if (state.isSignedIn) {
                state.idToken= auth.currentUser.get().getAuthResponse().id_token
            }
            state.isInitialized = true
            res()
        })
    },

    signIn: async () => {
        state.isInitialized = false
        await window.gapi.auth2.getAuthInstance().signIn()
        const auth = window.gapi.auth2.getAuthInstance()
        state.isSignedIn = auth.isSignedIn.get()
        if (state.isSignedIn) {
            state.idToken = auth.currentUser.get().getAuthResponse().id_token
        }
        state.isInitialized = true
    },

    signOut: async () => {
        state.isInitialized = false
        await window.gapi.auth2.getAuthInstance().signOut()
        state.isSignedIn = false
        state.isInitialized = true
        // Redirect
        window.location.href = '/'
    },

    initQuote: async () => {
        const response = await fetch(config.url + '/quotes/init?token=' + state.idToken, {
            method: 'GET',
            mode: 'cors',
            headers: { 'Content-Type': 'application/json' },
        })
        const res = await response.json()
        if (res.error) { throw Error(res.error)  }
        return res
    },

    getQuotes: async () => {
        const response = await fetch(config.url + '/quotes?token=' + state.idToken, {
            method: 'GET',
            mode: 'cors',
            headers: {
                'Content-Type': 'application/json'
            },
        })
        const res = await response.json()
        if (res.error) { throw Error(res.error)  }
        else {
            state.quotes = res.quotes
            state.loadingQuotes = false
        }
    },

    getQuoteByURLPath: async (urlPath) => {
        const response = await fetch(config.url + `/quotes/urlPath/${urlPath}?token=${state.idToken}`, {
            method: 'GET',
            mode: 'cors',
            headers: {
                'Content-Type': 'application/json'
            },
        })
        const res = await response.json()
        if (res.error) { throw Error(res.error)  }
        return res
    },

    saveQuote: async (data) => {
        const response = await fetch(config.url + '/quotes?token=' + state.idToken, {
            method: 'POST',
            mode: 'cors',
            headers: {
                'Content-Type': 'application/json'
            },
            body: JSON.stringify(data)
        })
        const res = await response.json()
        if (res.error) { throw Error(res.error)  }
        return res
    },

    destroyQuote: async (quoteID) => {
        const response = await fetch(config.url + `/quotes/${quoteID}?token=` + state.idToken, {
            method: 'DELETE',
            mode: 'cors',
            headers: {
                'Content-Type': 'application/json'
            },
        })
        const res = await response.json()
        if (res.error) { throw Error(res.error)  }
        return res
    },

})

export default state
